@import 'styles/_variables';

.step-one-ending {
    &__success-wrapper {
        background-color: $greyLighter2;
        border-radius: $borderRadius--medium;
        color: $black;
        width: 100%;
    }

    &__text-wrapper {
        margin: auto;
        text-align: left;
        width: fit-content;
    }

    &__content {
        padding: 4.8rem 3.2rem;
        position: relative;
    }

    &__image {
        display: flex;
        margin: 5rem auto 0;
    }

    &__text {
        font-size: 4.2rem;
        font-weight: $bold;
        line-height: 1.2;
        margin-top: 1.8rem;
        position: relative;
    }
}
