@import 'styles/_variables.scss';

.label {
    display: block;
    font-weight: bold;
    line-height: 2.4rem;
    margin: 0 0 1rem;
    margin-bottom: 1.6rem;
}

.form-group {
    margin-bottom: 3.6rem;
}

input {
    font-family: 'Mulish', sans-serif;
}

select {
    background-color: white;
    border: 0.1rem solid $grey;
    border-radius: $borderRadius;
    font-family: 'Mulish', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 100%;
    padding: 1.2rem $small-spacing;
}
