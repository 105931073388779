@import 'styles/_variables';

.main-steps {
    z-index: 1;
    height: 100%;
    display: grid;
    grid-row-gap: 1rem;
    grid-template-rows: max-content auto max-content;
    overflow-x: hidden;
    overflow-y: hidden;

    &__container {
        height: 100%;

        @media screen and (max-width: $breakpoint-mobile) {
            height: calc(100vh - #{$header-height});
        }
    }

    &__header {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6rem;
        text-align: center;
        width: 100%;
        min-height: fit-content;
    }

    &__subtitle {
        margin-top: 0.8rem;
    }

    &__content {
        overflow-y: auto;
    }
}

#scroll-anchor {
    display: none;

    @media screen and (max-width: $breakpoint-mobile) {
        top: 0;
        position: absolute;
    }
}
