@import 'styles/_variables';

.progressbar__title {
    font-size: 2.2rem;
    font-weight: $extraBold;
    margin-bottom: $small-spacing;
    text-align: left;
}

.progressbar__circle {
    background-color: $grey;
    border-radius: 50%;
    height: 0.8rem;
    margin-right: 1.2rem;
    width: 0.8rem;

    &:last-child {
        margin-right: 0;
    }
}

.progressbar__circle-container {
    display: inline-flex;
}

.progressbar__circle-filled {
    display: inline-flex;
    margin-right: 1.2rem;

    .progressbar__circle {
        &--filled:last-child {
            border-radius: 0 $borderRadius--large $borderRadius--large 0;
        }

        &--filled:first-child {
            border-radius: $borderRadius--large 0 0 $borderRadius--large;
        }

        &--filled:only-child {
            border-radius: $borderRadius--large;
        }

        &--filled {
            background-color: $black;
            border-radius: 0;
            height: 0.8rem;
            width: 1.9rem;
        }
    }
}
