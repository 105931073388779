@import 'styles/_variables.scss';

.pdf-front-page {
    &__container {
        background-color: $white;
        height: 84.1rem;
        width: 59.5rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 79rem;
        width: 100%;
    }

    &__footer {
        background-color: $greyLighter2;
        color: $black;
        font-size: 0.8rem;
        font-weight: $extraLight;
        height: 5.2rem;
        line-height: 1.2rem;
        padding: 0.8rem $x-small-spacing;
        text-align: center;
        width: 100%;
    }

    &__hester-logo-container {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
    }

    &__header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        .jobteaser-logo {
            margin: $medium-spacing;
        }
    }

    &__text-container {
        margin-left: $medium-spacing;
        h1 {
            font-size: 4.2rem;
            font-weight: $extraBold;
            line-height: 5rem;
        }

        .date {
            color: $greyGraphiteLight;
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        .subtitle {
            color: $black;
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin-top: $small-spacing;
        }
    }
}
