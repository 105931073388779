@import 'styles/_variables.scss';

.checkbox {
    align-items: baseline;
    display: flex;
    line-height: 2.2rem;
    margin-bottom: 1rem;
    text-align: left;

    input {
        border: 0.1rem solid $grey;
        flex: 0 0 1.6rem; // fix iPhone style
        margin-right: 1.2rem;
        transform: scale(1.2);
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        background-color: $lightRed;
        border: solid 0.1rem $pinkRed;
        color: $pinkRed;
        padding: 1rem;
    }
}
