@import 'styles/_variables';

.step-one-part-two__item-selected {
    background-color: $black;
    bottom: 9rem;
    max-width: $max-width-main;
    padding-top: $small-spacing;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 3;
}
