@import 'styles/_variables';

.step1-part2__card {
    background-color: $white;
    border: 0.1rem solid $greyCloudy;
    border-radius: $borderRadius--small;
    color: $black;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    font-weight: $extraBold;
    height: 100%;
    line-height: 2.4rem;
    min-height: 13.6rem;
    padding: $small-spacing $small-spacing 5.5rem;
    position: relative;
    text-align: left;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        font-size: 1.6rem;
    }

    > img {
        bottom: $small-spacing;
        display: block;
        position: absolute;
        right: $small-spacing;
    }
}

.step1-part2__card--selected {
    background-color: $primary;
    color: $white;
}
