@import 'styles/_variables';

.top-3-soft-skills {
    display: flex;
    justify-content: space-between;
    margin: $big-spacing 0 $medium-spacing;

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        margin: $medium-spacing 0 $medium-spacing;
    }

    li {
        background-color: $greyLighter2;
        border-radius: 1.6rem;
        height: 27.7rem;
        width: 26.4rem;

        @media screen and (max-width: $breakpoint-mobile) {
            height: 13.6rem;
            margin-bottom: $small-spacing;
            width: 100%;
        }

        > a {
            align-items: center;
            color: $black;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            text-decoration: none;

            @media screen and (max-width: $breakpoint-mobile) {
                flex-direction: row;
                justify-content: flex-start;
                img {
                    margin-left: -5rem;
                }
            }
        }

        .top-3-soft-skills__text-container {
            align-items: center;
            display: flex;
            flex-direction: column-reverse;
            font-size: 1.8rem;
            font-weight: $extraBold;
            line-height: 2.8rem;

            @media screen and (max-width: $breakpoint-mobile) {
                flex-direction: column;
                justify-content: center;
                line-height: 2.6rem;
                width: 22rem;
            }

            > span {
                margin-bottom: $small-spacing;

                @media screen and (max-width: $breakpoint-mobile) {
                    margin-bottom: 0.8rem;
                }
            }
        }

        .top-3-soft-skills__score-container {
            margin-bottom: $small-spacing;
            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 0;
            }
            > svg {
                margin: 0 0.3rem;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
