@import 'styles/_variables';

.descriptionExercise {
    font-size: 1.6rem;

    h3 {
        font-weight: $bold;
    }

    &__short {
        font-size: 1.4rem;
    }
}
