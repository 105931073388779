@import 'styles/_variables';

.shareTop3__container {
    height: 36rem;
    width: 53.9rem;

    .top3Image {
        &__top3 {
            background-color: $black;
            border-radius: 0;
        }

        &__skill-name {
            color: $white;
        }

        &__top3 .top3Image {
            &__background-shape1 {
                left: -2rem;
                top: -5rem;
            }

            &__background-shape2 {
                right: -5rem;
            }

            &__skill-name--2 {
                left: 30rem;
            }
        }
    }
}
