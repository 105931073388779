@import 'styles/_variables';

.resetPassword {
    height: 100%;
    padding: 2rem;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        margin: 0 auto;
        max-width: 56.4rem;
        padding: 3.2rem $big-spacing $big-spacing;
    }
}
