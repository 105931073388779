@import 'styles/_variables.scss';

.page-resume {
    .pdf__p {
        margin-top: 0.8rem;
    }

    &__shape {
        position: absolute;
        right: 7.2rem;
        top: 0;
    }

    &__next-step {
        margin-top: $base-spacing;

        ul {
            font-size: 1.2rem;
            line-height: 2rem;
            list-style: disc;
            margin-left: 2rem;

            a {
                color: unset;
                text-decoration: none;
            }
        }

        a {
            color: $black;
            font-weight: $bold;
        }
    }
}
