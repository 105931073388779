@import 'styles/_variables';

.stepList {
    &__step {
        border-left: 0.2rem solid $pinkRed;
        padding: $small-spacing;

        &--hidden {
            border-color: $greyGraphiteLight;
            color: $greyGraphiteLight;
        }
    }
}
