@import 'styles/_variables';

.form__container {
    display: flex;

    .panel-title {
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 2.4rem;
        margin-bottom: $x-small-spacing;
    }

    .panel-content {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}

.form__main {
    padding: 6.8rem 0 8.8rem;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: 0;
    }

    .main-card__title {
        color: white;
    }
}
