@import 'styles/_variables';

.description {
    p {
        font-size: 1.4rem;
    }

    img {
        margin-top: $base-spacing;
    }
}
