@import 'styles/_variables';

.instructions__buttons {
    display: flex;
    justify-content: space-between;

    &__skip {
        margin-right: 16px;
        width: 100%;
    }

    &__skippable {
        color: $greyGraphiteLight;
        font-size: 1.2rem;
        font-weight: $light;
        line-height: 2rem;
        margin-top: 0.8rem;
    }
}
