@import 'styles/_variables.scss';

.page-soft-skill {
    &__header {
        p {
            margin-top: $x-small-spacing
        }
    }

    &__definition {
        margin: $base-spacing 4rem;
    }

    &__shape {
        position: absolute;
        right: 7.2rem;
        top: 0;
    }

    &__list {
        font-size: 1.2rem;
        line-height: 2rem;
        list-style: disc;
        margin-left: 2rem;

        li {
            margin-top: 0.8rem;
        }
    }

    &__detailed-situation {
        margin-top: 3.9rem;
    }

    &__context {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1.3rem;

        p {
            margin-top: 0.8rem;
        }

        h4 {
            font-size: 1.2rem;
            line-height: 2rem;
            margin: 0;
        }
    }

    &__first-metacapability {
        margin-top: $base-spacing;
    }

    .meta-capability__list-item {
        p {
            font-size: 1.1rem;
            line-height: 1.6rem;
        }
    }
}
