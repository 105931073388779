@import 'styles/_variables';

.step-two__container {
    align-items: center;
    background-color: $greyLighter2;
    color: $black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow-x: hidden;
    padding: $big-spacing 0 $medium-spacing;
    position: relative;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        height: auto;
        padding: $medium-spacing 0 $base-spacing;
    }
}

.step-two__progressbar {
    display: flex;
    margin-bottom: $medium-spacing;

    > button {
        margin-right: $small-spacing;
    }
}

.step-two__slider-container {
    position: relative;
    width: 100%;
    z-index: 10;

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }

    // dirty trick to have all cards with the same maximum height
    // (sorry about all this ugliness)
    .slick-slide > div,
    .slick-slide > div > div,
    .slick-slide > div > div > div,
    .slick-slide > div > div > div > div {
        height: 100%;
    }
}

.step-two__illu {
    bottom: $small-spacing;
    height: 24.3rem;
    left: $small-spacing;
    position: absolute;

    @media screen and (max-width: 1280px) {
        display: none;
    }
}
