@import 'styles/_variables';

strong,
.strong {
    font-weight: $bold;
}

.center {
    text-align: center;
}

.base-margin {
    margin-top: $base-spacing;
}

.small-margin {
    margin-top: $small-spacing;
}

.block {
    display: block;
}

.with-side-padding {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}
