@import 'styles/_variables';

.results__container {
  .results__main {
    padding-top: 0;

    @media screen and (max-width: $breakpoint-mobile) {
      padding: $medium-spacing $base-spacing;
    }
  }

  .results__title {
    text-align: center;

    h1 {
      font-size: 3.2rem;
      font-weight: $extraBold;
      line-height: 4.2rem;
      margin-bottom: 0.8rem;
    }

    h2 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .results__buttons-container {
    display: flex;
    justify-content: center;


    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    .results__share-button {
      margin-right: $small-spacing;

      @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: $small-spacing;
        margin-right: 0;
      }
    }
  }

  .results__pdf {
    display: flex;
    flex-direction: column;
    line-height: 2.4rem;
    margin-top: $big-spacing;
    text-align: center;

    > h2 {
      font-size: 2.2rem;
      font-weight: $extraBold;
      line-height: 2.8rem;
      margin-bottom: 0.8rem;
    }

    > a {
      color: $black;
      display: block;
      font-weight: $bold;
      margin-top: 0.8rem;
    }

    .results__pdf-email {
      background-color: $greyLighter2;
      margin: $small-spacing auto;
      padding: $small-spacing;
    }

    .results__pdf-infos {
      font-weight: $bold;
    }

    > ul {
      list-style: inside;
    }

    .results__pdf-others {
      margin-top: $base-spacing;
    }
  }

  .results__top3-goals {
    align-items: center;
    display: flex;
    flex-direction: column;

    > h2 {
      font-size: 2.2rem;
      font-weight: $extraBold;
      line-height: 2.8rem;
      margin-bottom: 0.8rem;
      margin-top: $big-spacing;
    }

    > span {
      margin-bottom: 3.2rem;
    }
  }

  .results__back-to-step3 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        width: 100%;
        border: 1px solid #E3E3E7;
        border-radius: 4px;
        max-width: 66.4rem;
        margin: auto;
  }
}
