@import 'styles/_variables.scss';

.pdf__footer {
    background-color: $greyLighter2;
    color: $black;
    display: flex;
    font-size: 1rem;
    font-weight: $extraLight;
    height: 3.2rem;
    justify-content: space-between;
    line-height: 1.6rem;
    padding: 0.8rem $medium-spacing;
    width: 100%;
}
