@import 'styles/_variables.scss';

.page-achievements {
    &__achievement {
        background-color: $greyLighter;
        border-radius: $borderRadius--medium;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-top: $base-spacing;
        padding: $small-spacing;

        h3.pdf__title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.5rem;
            margin-bottom: $x-small-spacing;
        }
    }
}
