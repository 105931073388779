@import 'styles/_variables';

.situation-card__container {
    background-color: $white;
    border-radius: $borderRadius;
    color: $black;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 53.8rem;

    @media screen and (max-width: $breakpoint-mobile) {
        width: calc(100% - 4rem);
    }
}

.situation-card__content {
    flex-grow: 1;
    padding: $medium-spacing;
    text-align: center;
}

.situation-card__description {
    font-size: 1.8rem;
    font-weight: $extraBold;
    line-height: 2.6rem;
    margin-top: $small-spacing;
}

.situation-card__buttons-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding: 0 $medium-spacing $medium-spacing;

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        justify-content: flex-end;
    }

    .button--grey {
        color: $black;
    }
}

.situation-card__button {
    align-items: center;
    color: $white;
    display: flex;
    justify-content: center;
    margin-right: 1.8rem;
    min-height: 4.8rem;

    &:last-child {
        margin-left: 1.8rem;
        margin-right: 0;
    }

    svg {
        margin-right: 0.8rem;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: $medium-spacing;
        margin-right: 0;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
