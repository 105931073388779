/* MULISH */
@font-face {
    font-family: 'Mulish';
    font-weight: 400;
    src: url("./fonts/mulish/Mulish-Regular.ttf");
}

@font-face {
    font-family: 'Mulish';
    font-weight: 600;
    src: url("./fonts/mulish/Mulish-Medium.ttf");
}

@font-face {
    font-family: 'Mulish';
    font-weight: 700;
    src: url("./fonts/mulish/Mulish-Bold.ttf");
}

@font-face {
    font-family: 'Mulish';
    font-weight: 800;
    font-style: normal;
    src: url("./fonts/mulish/Mulish-ExtraBold.ttf");
}
