@import 'styles/_variables';

.button {
    background-color: $white;
    border: 0;
    border-radius: $borderRadius;
    color: inherit;
    font-size: 1.6rem;
    font-weight: $semiBold;
    height: auto;
    min-height: 5rem;
    padding: 1.2rem $base-spacing;
    text-decoration: none;

    &__spinner {
        height: 1.6rem;
    }

    &:hover {
        background-color: $greyCloudy;
    }

    &--small-padding {
        padding-left: $small-spacing;
        padding-right: $small-spacing;
    }

    &--black {
        background-color: $black;
        color: $white;

        &:hover {
            background-color: lighten($black, 20%);
        }

        &:disabled {
            background-color: lighten($black, 89%);
            cursor: not-allowed;
        }
    }

    &--blue {
        background-color: $blue;

        &:hover {
            background-color: lighten($blue, 5%);
        }

        &:disabled {
            background-color: $blueDark;
            cursor: not-allowed;
        }
    }

    &--green {
        background-color: $green;
        color: $white;

        &:hover {
            background-color: darken($green, 5%);
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &--grey {
        background-color: $greyLighter;

        &:hover {
            background-color: darken($greyLighter, 5%);
        }
    }

    &--primary {
        background-color: $primary;

        &:hover {
            background-color: darken($primary, 5%);
        }
    }

    &--block {
        width: 100%;
    }

    &--block-mobile {
        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }
    }

    &--outline {
        border: 0.2rem solid $black;
        color: $black;
    }

    &--transparent {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    img {
        margin-right: 1rem;
    }
}

.button--fixed {
    background-color: $white;
    max-width: $max-width-main;
    padding: $base-spacing 0;
    position: fixed;
    width: 100%;
    z-index: 2;

    @media screen and (max-width: $breakpoint-mobile) {
        bottom: 0;
        left: 0;
        max-width: none;
        padding: $base-spacing;
    }

    .main--full-height & {
        bottom: 0;
    }

    .main--large & {
        max-width: $max-width-main-large;

        @media screen and (max-width: $breakpoint-mobile) {
            max-width: none;
        }
    }

    .container--black & {
        background-color: $black;
    }

    .container--grey & {
        background-color: $backgroundColor;
    }
}
