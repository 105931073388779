@import 'styles/_variables';

.roti-section {
  .label {
    &.small {
        font-size: 1.6rem;
        font-weight: $light;
        line-height: 2.4rem;
    }
  }

  .choicefield {
    background-color: $greyLighter2;
    border: 0.2rem solid $greyLighter2;
    height: 4rem;
    margin: 0;
    padding: 0.6rem 0;
    text-align: center;
    width: 4rem;
    &:hover,
    &--active {
        border-color: $primary!important;
    }
  }
}
