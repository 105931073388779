@import 'styles/_variables';

.home {
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: $breakpoint-mobile) {
        height: 100vh;
    }
}
