@import 'styles/_variables';

.export-form {
    margin-bottom: $medium-spacing;

    &__label {
        font-size: 1.4rem;
        margin-bottom: $x-small-spacing;
    }

    &__language {
        margin-top: $medium-spacing;

        .field {
            margin-bottom: 0;
        }
    }

    &__dates {
        margin-top: $medium-spacing;

        @media screen and (min-width: $breakpoint-mobile) {
            display: inline-flex;
            width: 100%;

            > :first-child {
                margin-right: $small-spacing;
            }

            .field {
                margin-bottom: 0;
                width: 50%;
            }
        }
    }

    &__button {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: $medium-spacing;
    }
}
