@import 'styles/_variables';

.nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 2rem $medium-spacing 0;
    width: 23rem;
    z-index: 1;
}

.nav__button {
    &:hover {
        opacity: 0.6;
    }

    &--disabled {
        opacity: 0.6;

        &:hover {
            opacity: 0.6;
        }
    }
}

.question {
    align-items: flex-start;
    display: flex;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

.question__current {
    font-weight: 900;
}
