@import 'styles/_variables';

.field__input {
    border: solid 0.1rem $grey;
    border-radius: $borderRadius;
    font-size: 1.6rem;
    padding: 1.2rem $small-spacing;
    width: 100%;

    &::placeholder {
        color: $grey;
    }
}
