@import 'styles/_variables';

.score {
    &__item {
        display: flex;
        margin-bottom: $small-spacing;

        p {
            margin-top: 0;
        }
    }

    &__stars {
        border: 0.1rem solid #E3E3E7;
        border-radius: $borderRadius;
        display: inline-flex;
        height: 4.7rem;
        justify-content: center;
        margin-right: $small-spacing;
        min-width: 8.5rem;
        padding: $small-spacing;

        svg {
            height: 1.2rem;
            width: 1.2rem;
        }

        svg:nth-child(2) {
            margin-left: $x-small-spacing;
        }

        svg:nth-child(3) {
            margin-left: $x-small-spacing;
        }
    }
}
