@import 'styles/_variables';

.profile-details {
    &__category {
        margin-bottom: 0.8rem;

        h3 {
            font-size: 1.4rem;
            font-weight: $bold;
            line-height: 2.2rem;
        }
    }

    &__categories {
        margin-top: $base-spacing;
    }

    &__soft-skills {
        display: flex;
        margin-top: 0.8rem;

        .soft-skill-picto {
            border: 0.1rem solid #E3E3E7;
            border-radius: $borderRadius;
            margin-right: $small-spacing;
            padding: 1rem $small-spacing;
            width: 13rem;

            &__picto {
                width: 4.2rem;
            }

            span {
                color: $black;
                font-size: 0.9rem;
                margin-bottom: $x-small-spacing;
            }
        }
    }
}
