.page-interview-advices {
    .pdf__p {
        margin-top: 0.8rem;
    }

    &__list {
        font-size: 1.2rem;
        line-height: 2rem;
        list-style: disc;
        margin-left: 2rem;

        li {
            margin-top: 0.8rem;
        }
    }
}
