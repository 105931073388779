@import "styles/_variables";

.home-header {
    background-color: $black;
    overflow: hidden;

    &__header-menu {
        background: none;
        position: relative;
        z-index: 100;

        &__wrapper {
            padding: $base-spacing;
        }

        &__pipe {
            border-left: 0.1rem solid $white;
            height: 2.2rem;
            margin-left: $base-spacing;
            margin-right: $base-spacing;
        }

        &--right {
            align-items: center;
            display: flex;
        }

        .button {
            min-height: auto;
        }
    }

    &__title {
        color: $white;
        font-size: 4.2rem;
        font-weight: $extraBold;
        line-height: 1.1;
        margin-bottom: 0.8rem;
        max-width: 63rem;

        @media screen and (min-width: $breakpoint-mobile) {
            font-size: 6rem;
            max-width: 80rem;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            max-width: 60rem;
        }

        span {
            display: block;
            font-size: 4.2rem;
            margin-top: $small-spacing;

            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 2.6rem;
            }
        }
    }

    &__container {
        align-items: center;
        display: grid;
        grid-row-gap: 0.5rem;
        grid-template-columns: 1fr;
        grid-template-rows: 30rem auto;
        margin-bottom: -0.1rem;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            max-height: 100rem;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            grid-column-gap: $x-small-spacing;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            min-height: 50rem;
        }
    }

    &__intro {
        padding: 0 2rem;

        @media screen and (min-width: $breakpoint-desktop) {
            margin-left: calc(100% - 60rem);
        }
    }

    &__wrapper-illustration {
        height: 100%;
        margin-left: -20%;

        @media screen and (min-width: $breakpoint-mobile) {
            margin-left: -35%;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            margin: 0;
            max-height: 50rem;
        }
    }

    &__illustration {
        height: 100%;
        max-height: 65rem;
        max-width: unset;
        width: 100%;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-left: 3rem;
        }
    }

    &__subtitle {
        color: $white;
        font-size: 2.2rem;
        font-weight: $bold;
        line-height: 2.8rem;
        margin-bottom: $medium-spacing;
    }
}
