@import 'styles/_variables';

.error-page__container {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.error-page-header__header {
  padding: $medium-spacing;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: $base-spacing 2rem;
  }
}

.error-page-main {
  align-items: center;
  display: flex;
  justify-content: center;

  .error-page-main__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem 2rem 0 2rem;

    @media screen and (min-width: $breakpoint-mobile) {
      border: 0.2rem solid $black;
      border-radius: $borderRadius;
      justify-content: center;
      margin-top: 7rem;
      min-height: 42rem;
      min-width: 96.4rem;
      padding: 6rem $big-spacing;
      position: relative;
    }

    h1 {
      color: $black;
      font-size: 3.2rem;
      font-weight: $extraBold;
      margin-bottom: $small-spacing;
    }

    span {
      margin-bottom: $small-spacing;
      max-width: 38rem;
    }

    img {
      margin-top: 2rem;

      @media screen and (min-width: $breakpoint-mobile) {
        margin-top: 0;
        position: absolute;
        right: 6.4rem;
      }
    }
  }
}
