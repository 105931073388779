@import 'styles/_variables.scss';

.choicefield__wrapper {
    display: flex;
    flex-wrap: wrap;
}

.choicefield {
    margin: 0.8rem $small-spacing 0.8rem 0;
    padding: 0.8rem $small-spacing;
    border: 0.2rem solid $grey;
    border-radius: 10rem;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (max-width: $breakpoint-mobile) {
        white-space: normal;
    }

    &:hover,
    &--active {
        border-color: $primary;
    }

    input {
        position: fixed;
        height: 0;
        width: 0;
        appearance: none;
        border-color: transparent;
        background: transparent;
    }

    label {
        color: $black;
        font-size: 1.4rem;

        a {
            color: $black;
        }
    }

    &--error {
        padding: 1rem;
        border: solid 0.1rem $pinkRed;
        background-color: $lightRed;
        color: $pinkRed;
    }
}
