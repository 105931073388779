@import 'styles/_variables';

.panel__backdrop {
    overflow: auto;
}

.panel {
    background-color: $black;
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    width: $panel-width;
    z-index: 1;

    @media screen and (max-width: $breakpoint-mobile) {
        background-color: transparent;
        height: auto;
        min-width: auto;
        width: 100%;
    }

    &.panel--open {
        background-color: $black;

        @media screen and (max-width: $breakpoint-mobile) {
            height: 100%;
        }
    }
}

.panel__header {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-mobile) {
        .container--black & {
            background-color: $black;
        }

        .container--grey & {
            background-color: $backgroundColor;
        }

        .panel--open & {
            background-color: $backgroundColor;
        }
    }

    .button {
        min-height: 2.4rem;
    }
}

.panel__title {
    font-size: 2.2rem;
}

.panel__inner {
    border-bottom: 0.1rem solid $greyGraphite;
    height: 100%;
    margin: 4rem $base-spacing 0;
}
