@import 'styles/_variables.scss';

.page-job-suggestions {
    .pdf__p {
        margin-top: 0.8rem;
    }

    &__zone {
        margin-top: $base-spacing;

        .pdf__p {
            font-size: 14rem;
            line-height: 2rem;
        }

        ul {
            font-size: 1.2rem;
            line-height: 2rem;
            list-style: disc;
            margin-left: 2rem;

            li {
                margin-top: 0.8rem;
            }
        }
    }
}
