@import 'styles/_variables';

.language-switcher {
    .field {
        margin-bottom: 0;
    }

    &--black {
        .select_container {
            background-color: $black;
            border-color: $greyGraphiteLight;
            color: $white;
        }

        .select {
            &__control {
                background-color: $black;
                color: $white;
                cursor: pointer;

                &--is-focused {
                    border-color: $white !important;
                }
            }

            &__menu {
                background-color: $black;
                border: 0.1rem solid $greyGraphiteLight;
                color: $white;
            }

            &__menu-list {
                background-color: $black;
                color: $white;
            }

            &__option {
                background-color: $black !important;
                color: $white;
                cursor: pointer;
            }

            &__indicator {
                color: $white !important;
            }

            &__single-value {
                color: $white;
            }
        }
    }

    // For HomeHeader and Footer
    &__small {
        .field input {
            cursor: pointer;
            padding: 0;
        }

        @media screen and (min-width: $breakpoint-mobile) {
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: $black;
            border: 0;
            color: $white;
            max-width: 4.6rem;
            padding: 0;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            background-color: $white;
            border-radius: $borderRadius;
            color: $black;
            max-width: 12.3rem;
            padding: 0.7rem;
        }

        .select {
            &__control {
                border: unset;
                min-height: unset;
            }

            &__value-container {
                color: $white;
                padding: 0;
            }

            &__single-value {
                margin: 0 $x-small-spacing 0 0;
            }

            &__indicator {
                padding: 0;

                svg {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }
    }
}
