@import 'styles/_variables';

.gender__wrapper {
    display: flex;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
}

.gender__button {
    align-items: center;
    border: 0.2rem solid $grey;
    border-radius: 10rem;
    cursor: pointer;
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    margin-right: $small-spacing;
    padding: 0.8rem $small-spacing;

    &--active,
    &:hover {
        border-color: $primary;

        svg g {
            stroke: $black;
        }
    }

    input {
        appearance: none;
        background: transparent;
        border-color: transparent;
        height: 0;
        position: fixed;
        width: 0;
    }
}

.gender__button--other {
    &.gender__button--active,
    &:hover {
        svg g {
            fill: $black;
            stroke-width: 0;
        }
    }
}
