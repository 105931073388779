@import 'styles/_variables.scss';

.back-page {
    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .shape-bottom {
            align-self: flex-start;
            height: 21rem;
            margin-top: auto;
            width: 17.7rem;
        }

        .shape-top {
            align-self: flex-end;
            height: 28.6rem;
            width: 19.7rem;
        }
    }

    &__content {
        text-align: center;

        img {
            width: 10rem;
        }
    }

    &__subtitle {
        font-size: 1.6rem;
        font-weight: $light;
        line-height: 2.4rem;
    }

    &__title {
        font-size: 3.2rem;
        font-weight: $extraBold;
        line-height: 4.2rem;
        margin: $medium-spacing auto $small-spacing;
        max-width: 40rem;
    }
}
