@import "styles/_variables";

.loading-screen {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    text-align: center;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
    }
}

.loading-illustration {
    height: 100%;
    margin-top: $big-spacing;
    overflow-y: hidden;
    width: 100%;

    &__image {
        bottom: 10rem;
        position: absolute;
        z-index: 10;
    }

    &__vector {
        bottom: -5%;
        height: 130%;
        position: relative;
        width: 100%;

        @media screen and (max-width: $breakpoint-mobile) {
            bottom: -35%;
            height: 100%;
        }
    }
}

.loading-screen__title {
    margin-bottom: 2rem;
}
