@import 'styles/_variables.scss';

.soft-skill-picto {
    align-content: center;
    display: flex;
    flex-direction: column;
    width: 6rem;

    &:last-of-type {
        margin-right: 0;
    }

    .soft-skill-picto__picto {
        align-self: center;
        width: 20mm;
    }

    span {
        color: $pinkRed;
        display: block;
        font-size: 3mm;
        font-weight: bold;
        text-align: center;
    }

    .softSkill-score__container {
        align-self: center;
        display: flex;

        > svg {
            height: 3mm;
            margin: 0 0.5mm;
            width: 3mm;
        }
    }

    .softSkill-score__star {
        height: 4mm;
        margin: 0 0.5mm;
        vertical-align: top;
        width: 4mm;
    }
}
