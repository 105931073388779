@import 'styles/_variables';

.tag {
    background-color: $primary;
    border-radius: $borderRadius--medium;
    color: $black;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 0.2rem 1.2rem;

    &--black {
        background-color: $black;
        color: $white;
    }

    &--grey {
        background-color: $greyCloudy;
    }
}
