@import 'styles/_variables';

.error-page-main {
  .language-switcher {
    border: 0;
    max-width: 38rem;
    padding: 0;
    width: 100%;

    .label {
      font-size: 1.4rem;
    }

    > select {
      background-color: $white;
      border: 1px0.1rem solid $grey;
      border-radius: $borderRadius;
      color: $black;

      > option {
        background-color: $white;
        color: $black;
      }
    }
  }
}
