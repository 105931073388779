@import 'styles/_variables.scss';

.meta-capability-score {
    padding: 1mm 2mm;
    display: flex;
    flex-shrink: 0;
    border: 0.1rem solid #E3E3E7;
    border-radius: $borderRadius;
    min-width: 13rem;
    justify-content: center;
    align-items: center;

    span {
        display: block;
        margin-right: 2mm;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.2rem;
    }

    &__stars {
        svg {
            width: 1.2rem;
            height: 1.2rem;
        }

        svg:nth-child(2) {
            margin-left: 0.2rem;
        }

        svg:nth-child(3) {
            margin-left: 0.2rem;
        }
    }
}
