@import 'styles/_variables';

.top3Image__top3 {
    background-color: $greyLighter2;
    border-radius: $borderRadius--medium;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        min-height: 37.6rem;
    }

    .top3Image__background-shape1 {
        left: 0;
        position: absolute;
        top: -4rem;
    }

    .top3Image__background-shape2 {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    div {
        align-items: center;
        display: flex;
        margin-bottom: 4rem;

        &:last-child {
            margin: 0;
        }
    }

    .top3Image__skill-image {
        height: 18rem;
        position: absolute;
        width: 18rem;

        &--1 {
            left: -3rem;
            top: -2rem;
        }

        &--2 {
            bottom: 13rem;
            right: -3rem;
        }

        &--3 {
            bottom: -4rem;
            left: 4rem;
        }
    }

    .top3Image__skill-name {
        color: $black;
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 2.6rem;
        position: absolute;
        text-align: center;

        &--1 {
            left: 2rem;
            max-width: 15rem;
            top: 14rem;
        }

        &--2 {
            bottom: 29rem;
            max-width: 15rem;
            right: 1rem;
        }

        &--3 {
            bottom: 4rem;
            left: 20rem;
        }
    }

    @media screen and (max-width: $breakpoint-small-mobile) {
        .top3Image__skill-image {
            &--2 {
                bottom: 8rem;
            }

            &--3 {
                left: 0;
            }
        }

        .top3Image__skill-name {
            &--2 {
                bottom: 24rem;
            }

            &--3 {
                left: 16rem;
            }
        }
    }

    @media screen and (min-width: $breakpoint-mobile) {
        height: 28rem;

        .top3Image__skill-image {
            &--1 {
                left: -3rem;
                top: -3rem;
            }

            &--2 {
                bottom: -4.5rem;
                right: 22rem;
                top: auto;
            }

            &--3 {
                bottom: auto;
                left: auto;
                right: 2rem;
                top: -4rem;
            }
        }

        .top3Image__skill-name {
            &--1 {
                left: 2rem;
                top: 14rem;
            }

            &--2 {
                bottom: 4rem;
                left: 37rem;
                right: auto;
                top: auto;
            }

            &--3 {
                bottom: auto;
                left: auto;
                right: 15rem;
                top: 5rem;
            }
        }
    }
}
