@import 'styles/_variables';

.admin {
    padding: 6.8rem 0 8.8rem;
    text-align: left;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: 0;
    }

    .main-card {
        margin-top: $medium-spacing;
    }

    h1, h2 {
        font-weight: $extraBold;
        margin-bottom: $medium-spacing;
    }

    ul {
        padding-left: $base-spacing;
    }

    &__back-button {
        color: $white !important;
        padding: 0 !important;
    }

    &__button-container {
        margin: 2rem 0;
        text-align: center;
    }

    &__content {
        text-align: left;
    }

    &__list {
        font-size: 1.6rem;
        list-style: disc;
        margin-bottom: $medium-spacing;
    }

    &__title {
        font-size: 3rem;
    }
}
