@import 'styles/_variables';

.step-information-modal {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    z-index: 900;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        height: auto;
        left: 50%;
        max-width: 56.4rem;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

.step-information-modal__inner {
    background: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
    padding: $base-spacing;

    @media screen and (min-width: $breakpoint-mobile) {
        border-radius: $borderRadius;
        padding: 3.2rem $big-spacing $big-spacing;
    }

    > h1 {
        font-size: 3.2rem;
        font-weight: $extraBold;
        margin-top: $base-spacing;
    }

    > h2 {
        font-size: 2.2rem;
        font-weight: $extraBold;
        margin-top: 3.2rem;
    }

    > p {
        font-weight: $semiBold;
        line-height: 2.4rem;
        margin-top: 0.8rem;

        > span {
            display: block;
        }
    }
}

.step-information-modal__image {
    align-self: flex-end;
    margin-bottom: -3.7rem;
    margin-top: auto;
    width: 30rem;

    @media screen and (min-width: $breakpoint-mobile) {
        margin-top: 3rem;
    }
}

.step-information-modal__close {
    cursor: pointer;
    height: 2rem;
    position: absolute;
    right: 3.5rem;
    top: 3.5rem;
    width: 2rem;

    @media screen and (min-width: $breakpoint-mobile) {
        height: 1.6rem;
        width: 1.6rem;
    }
}

.step-information-modal__header-menu {
    margin: -0.8rem -0.8rem 0;
}
