@import 'styles/_variables';

.field {
    margin-bottom: 1.6rem;
    text-align: left;
    position: relative;

    label {
        display: block;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    span {
        display: block;
        margin: 1rem 0;
        padding-bottom: 0.5rem;
        text-align: left;
        font-size: 1.1rem;
        color: $pinkRed;
        border-bottom: 0.1rem solid lighten($pinkRed, 30%);
    }

    &--error {
        input {
            border: solid 0.1rem $pinkRed;
            background-color: $lightRed;
            color: $pinkRed;

            &::placeholder {
                color: lighten($pinkRed, 15%);
            }
        }
    }

    &--warning {
        input,
        input::placeholder,
        span,
        a {
            color: $emerald;
            border-color: $emerald;
        }

        input {
            border: solid 0.1rem;
            background-color: lighten($emerald, 65%);

            &::placeholder {
                color: $emerald;
            }
        }

        span {
            border-bottom: 0.1rem solid;
            line-height: 1.27;
        }
    }
}

.field__label--hidden {
    position: absolute;
    visibility: hidden;
}
