@import 'styles/_variables';

.admin-table {
    margin-bottom: 3rem;
    width: 100%;

    th,
    td {
        border: 0.1rem solid $grey;
        padding: 1rem;
        vertical-align: middle;
    }

    &__header {
        background-color: $primary;
        color: $white;
        font-weight: $bold;
        text-align: center;
    }
}
