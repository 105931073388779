@import 'styles/_variables';

.main__wrapper {
    height: 100%;
    min-height: 100vh;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    width: 100%;
    z-index: 10;
}

.main {
    margin: auto;
    margin-top: $header-height;
    max-width: $max-width-main;
    padding: $medium-spacing 2rem;

    @media screen and (min-width: $breakpoint-mobile) {
        margin-top: 0;
        text-align: center;
    }

    &--large {
        max-width: $max-width-main-large;
    }

    &--full-height {
        padding-top: $big-spacing;

        @media screen and (max-width: $breakpoint-mobile) {
            padding-top: $medium-spacing;
        }
    }

    &--full-width {
        max-width: unset;
        padding: $medium-spacing 0;
    }

    .container--black & {
        background-color: $black;
        color: $white;
    }
}
