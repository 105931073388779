@import 'styles/_variables';

.soft-skill-page__main {
    padding: 0;

    .soft-skill-page__main-inner {
        margin: 0.6rem 0;
        max-width: unset;

        @media screen and (max-width: $breakpoint-mobile) {
            margin: $small-spacing 0;
            padding: 0 2rem;
        }
    }

    .soft-skill-page__skill {
        align-items: center;
        background-color: $greyLighter2;
        border-radius: 1.6rem;
        display: flex;
        flex-direction: column;
        margin-bottom: $big-spacing;
        padding: $medium-spacing $big-spacing;
        text-align: center;

        @media screen and (max-width: $breakpoint-mobile) {
            margin-bottom: $medium-spacing;
        }

        > img {
            margin-bottom: 1rem;

            @media screen and (max-width: $breakpoint-mobile) {
                margin-bottom: 0;
            }
        }

        > h2 {
            font-size: 3.2rem;
            font-weight: $extraBold;
            line-height: 4.2rem;
            margin-bottom: $small-spacing;
        }

        > span {
            font-size: 1.6rem;
            font-weight: $semiBold;
            line-height: 2.4rem;
        }
    }

    .soft-skill-page__situation__wrapper {
        margin: 0 auto;
        max-width: 58.8rem;
        text-align: center;

        > strong {
            display: block;
            font-size: 1.8rem;
            line-height: 2.6rem;
            margin: $small-spacing 0 $big-spacing;
        }
    }

    .soft-skill-page__experience {
        display: flex;

        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
        }

        .starting-quote {
            align-self: flex-start;
            margin-top: $small-spacing;

            @media screen and (max-width: $breakpoint-mobile) {
                margin: 0 -3rem $small-spacing;
            }
        }

        .ending-quote {
            align-self: flex-end;
            margin-bottom: $small-spacing;

            @media screen and (max-width: $breakpoint-mobile) {
                margin-right: -2rem;
            }
        }

        .content {
            padding: 0 2.6rem;
            text-align: left;
            width: 100%;

            @media screen and (max-width: $breakpoint-mobile) {
                padding: 0;
            }

            > h4 {
                font-weight: $bold;
                margin-bottom: $small-spacing;
            }

            > p {
                font-style: italic;
                margin-bottom: $base-spacing;
            }
        }
    }
}
