@import 'styles/_variables';

.top3-achievements {
    max-width: 66.4rem;
    width: 100%;

    li {
        background-color: $greyLighter2;
        border-radius: $borderRadius;
        display: flex;
        margin-bottom: $small-spacing;
        padding: $medium-spacing;

        @media screen and (max-width: $breakpoint-mobile) {
            align-items: flex-start;
            flex-direction: column;
            padding: $small-spacing;
        }

        .tag {
            background-color: $greyCloudy;
            font-size: 1.2rem;
            font-weight: normal;
            line-height: 2rem;
        }

        .top3-achievements__label {
            font-size: 1.8rem;
            font-weight: $extraBold;
            line-height: 2.6rem;
            margin-left: $small-spacing;

            @media screen and (max-width: $breakpoint-mobile) {
                margin-left: 0;
                margin-top: $small-spacing;
            }
        }
    }
}
