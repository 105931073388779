@import 'styles/_variables.scss';

.pdf-intro2 {
    ul {
        font-size: 1.2rem;
        font-weight: $light;
        line-height: 2rem;
        list-style: disc inside;
        margin-left: 0.6rem;

        li {
            margin-top: 0.8rem;
        }

        strong {
            font-weight: $bold;
        }
    }

    &__shape {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__title-container {
        display: flex;
        margin-top: $base-spacing;

        h3 {
            font-size: 1.6rem;
            font-weight: $bold;
            line-height: 2.8rem;
            margin-left: 0.8rem;
        }
    }

    &__conclusion {
        font-size: 1rem;
        font-weight: $extraLight;
        line-height: 2rem;
        margin-top: $base-spacing;
    }
}
