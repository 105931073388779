p {
    margin-top: 1rem;
}

.title {
    font-size: 3.2rem;
    font-weight: $extraBold;
    line-height: 4.2rem;
}

.title-complement {
    color: $greyGraphite;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: -2.4rem !important; // cancel h2 margin bottom
}

.title--small {
    font-size: 1.6rem;
}

.title--submedium {
    font-size:  2.2rem;
    line-height:  2.8rem;
}

.title--medium {
    font-size:  2.6rem;
}

.text--big {
    font-size: 1.8rem;
}

.text--medium {
    font-size: 1.6rem;
}

.text--small {
    font-size: 1.4rem;
}

.subtitle {
    color: $greyGraphite;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-top: $x-small-spacing;
}

.link {
    color: $black;
    text-decoration: underline;
}

.error {
    color: $pinkRed;
}
