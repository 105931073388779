@import 'styles/_variables';

.end-form__container {
    color: $black;
    text-align: left;
    width: 100%;

    h2 {
        font-size: 2.2rem;
        font-weight: $extraBold;
        line-height: 2.8rem;
        margin-bottom: $medium-spacing;
    }

    .label {
        &.small {
            font-size: 1.4rem;
            font-weight: $semiBold;
            line-height: 2.2rem;
        }
    }

    button {
        display: block;
        font-weight: $light;
        margin: 0 auto;
        max-width: 25.3rem;
        padding: 1.2rem $base-spacing;

        @media screen and (max-width: $breakpoint-mobile) {
            max-width: unset;
            width: 100%;
        }
    }
}

.slider-form {
    bottom: 0;
    height: 2rem;
    margin-top: 4.5rem;
    position: absolute;
    width: 100%;
}

.slider-form__thumb {
    background-color: $primary;
    border-radius: 5rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
    cursor: pointer;
    height: 2rem;
    outline: none;
    text-align: center;
    top: 0.5rem;
    width: 2rem;
}

.slider-form__value {
    background-color: $white;
    border: 0.1rem solid #C4C4C4;
    border-radius: $borderRadius;
    font-size: 1.2rem;
    left: 0.8rem;
    line-height: 2.5rem;
    position: relative;
    top: -4rem;
    width: 6rem;

    &::before, &::after {
        content: "";
        position: absolute;
    }

    &::before {
        background-color: white;
        border-left: 0.1rem solid $greyLighter3;
        bottom: -0.6rem;
        height: 1rem;
        left: -0.1rem;
        width: 0.6rem;
        z-index: 99;
    }

    &::after {
        border-right: 0.1rem solid $greyLighter3;
        bottom: -0.4rem;
        height: 0.8rem;
        left: -0.6rem;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 1rem;
        z-index: 100;
    }
}

.slider-form__track {
    background-color: $greyCloudy;
    border-radius: 2rem;
    height: 0.8rem;
    top: 1.2rem;

    &-0 {
        background-color: $primary;
    }
}

.roti__choicefield-wrapper {
    display: flex;
    justify-content: space-between;
}

.roti__choicefield-label-wrapper {
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    margin-top: 0.8rem;
}
