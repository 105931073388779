@import 'styles/_variables.scss';

.meta-capability {
    margin-bottom: $base-spacing;

    &__header {
        align-content: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        h3.pdf__title {
            margin: 0;
        }
    }

    p {
        margin-top: 0;
    }

    &__list-item {
        list-style: disc;
        margin-left: $base-spacing;
        margin-top: 0.8rem;
    }
}
