@import 'styles/_variables';

.main-inner {
    @media screen and (min-width: $breakpoint-mobile) {
        margin: 8rem auto;
        max-width: $max-width-main;
    }

    &--large {
        max-width: $max-width-main-large;
    }
}
