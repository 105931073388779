@import 'styles/_variables';

.step1-part2__grid {
    column-gap: $small-spacing;
    display: grid;
    // limits the height on big screens
    grid-auto-rows: min-content;
    grid-template-columns: 1fr 1fr;
    min-height: 100%;
    row-gap: $small-spacing;
    width: 100%;

    @media screen and (min-width: $breakpoint-mobile) {
        grid-template-columns: repeat(3, 1fr);
    }
}
