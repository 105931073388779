@import 'styles/_variables.scss';

.page-hester-model {
    .pdf__p {
        margin-top: 0.8rem;
    }

    &__diagram {
        text-align: center;
        width: 100%;

        img {
            margin: $base-spacing 0 $small-spacing;
            width: 42.7rem;
        }
    }
}
