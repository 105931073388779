@import 'styles/_variables';

.results-panel {
  .panel-title {
    font-size: 1.8rem;
    font-weight: $extraBold;
    line-height: 2.4rem;
    margin-bottom: $x-small-spacing;
  }

  .panel-content {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .panel__download-pdf-button {
    color: $black;
    font-size: 1.6rem;
    margin-top: $base-spacing;
    padding: 1.2rem 2rem;
    width: 100%;

    img {
      margin-bottom: 0.1rem;
      margin-right: 0.8rem;
    }
  }

  .panel__share-button {
    border: 0.1rem solid $white;
    margin-top: $small-spacing;
    padding: 1.2rem 2.25rem;
    width: 100%;
  }
}
