@import 'styles/_variables';

.main-with-back-button__wrapper {
    height: 100%;
    min-height: 100vh;
    position: relative;
    width: 100%;
    z-index: 10;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-top: $header-height;
    }

    .main-with-back-button__title {
        font-size: 3.2rem !important;
        margin-bottom: $base-spacing;
        text-align: left;
    }

    .main-with-back-button__button-container {
        .main-with-back-button__back-button {
            align-items: center;
            color: $greyGraphiteLight;
            display: flex;
            height: unset;
            margin-left: 2rem;
            margin-top: $medium-spacing;
            text-decoration: none;
            width: unset;

            > span {
                font-size: 1.4rem;
                line-height: 2.2rem;
                padding-left: 1rem;
            }
        }
    }

    .main {
        margin: auto;
        max-width: $max-width-main;

        &--large {
            max-width: $max-width-main-large;
        }
    }
}
