@import 'styles/_variables.scss';

.secondary-menu {
    margin: $base-spacing;

    &__link {
        a, button {
            color: $white;
            font-size: 1.2rem;
            font-weight: $semiBold;
            line-height: 2.4rem;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
