@import 'styles/_variables';

.step2-main {
    .main {
        padding: $big-spacing 0 0;
    }

    .main-steps {
        &__container {
            @media screen and (max-width: $breakpoint-mobile) {
                height: unset;
            }
        }

        &__content {
            margin-bottom: -1rem;
        }

        &__header {
            padding: 0 2rem;
        }
    }
}
