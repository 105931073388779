@import 'styles/_variables';

.situation-card {
    background-color: $greyLighter2;
    border-radius: $borderRadius;
    color: $black;
    padding: $medium-spacing;
    text-align: left;
    width: 100%;

    p {
        font-size: 1.8rem;
        font-weight: $bold;
        line-height: 2.6rem;
    }
}
