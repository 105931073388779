@import 'styles/_variables';

.step3-part1-selection__footer {
    width: 100%;
}

.step3-part1-selection__cards-container {
    margin: 3.2rem 0;
}

.step3-part1-selection__description {
    font-weight: $bold;
}
