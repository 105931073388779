@import 'styles/_variables';

.step-3 {
    &__dates {
        @media screen and (min-width: $breakpoint-mobile) {
            display: inline-flex;
            width: 100%;

            > :first-child {
                margin-right: $base-spacing;
            }

            .field {
                width: 50%;
            }
        }
    }

    &__wrapper {
        background-color: $white;
        color: $black;
        margin: $medium-spacing 0 0;
        width: 100%;

        @media screen and (min-width: $breakpoint-mobile) {
            border-radius: $borderRadius--medium;
            max-width: 60rem;
            width: 100%;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
    }
}
