@import 'styles/_variables';

.pdf-result {
    &__shape {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    &__content {
        padding: 0 4rem;
    }

    ol {
        .soft-skill-picto {
            margin-top: $small-spacing;
        }

        li {
            display: flex;
            margin-top: $base-spacing;

            h4 {
                font-size: 1.6rem;
                font-weight: $bold;
                line-height: $base-spacing;
            }

            p {
                margin-top: 0;
            }
        }
    }

    &__soft-skill {
        margin-left: $base-spacing;
    }
}
