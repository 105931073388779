@import 'styles/_variables';

.field-date {
    input {
        border: 0.1rem solid;
        border-color: $borderColor !important;
        border-radius: $borderRadius;
        box-shadow: none;
        padding: 1.2rem $small-spacing;
        width: 100%;
    }

    &.field--error input {
        border-color: $pinkRed !important;
    }
}
