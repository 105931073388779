@import 'styles/_variables';

.main-card {
    background-color: $white;
    border-radius: $borderRadius--large;
    margin: 0 auto;
    padding: $medium-spacing;
    position: relative; // need for absolute child
    width: 100%;

    &.main-card--no-padding {
        @media screen and (min-width: $breakpoint-mobile) {
            padding: 0;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 0;
        padding: $base-spacing 2rem;
    }
}

.main-card--small {
    max-width: 53.6rem;
}

.main-card__title {
    margin-bottom: $medium-spacing;

    @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: 0;
        padding: $base-spacing 2rem;
    }
}

.main-card__header {
    display: flex;
    margin: 0 auto;
    max-width: 86.4rem;
    width: 100%;

    @media screen and (max-width: $breakpoint-mobile) {
        max-width: unset;
        padding: 0 2rem;
    }
}
