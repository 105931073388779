@import 'styles/_variables.scss';

.with-padding {
    padding: $medium-spacing 4rem;
}

.fill-height {
    height: 100%;
}

.colored--black {
    background-color: $black;
}

.colored--grey {
    background-color: $greyLighter;
}
