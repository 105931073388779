@import 'styles/_variables.scss';

.pdf {
    &__container {
        background-color: $white;
        height: 84.1rem;
        width: 59.5rem;

        &.backpage {
            // Height adjustement for the backpage because puppeteer render an additional page else.
            height: 84rem;

            .pdf__content {
                height: 80.9rem;
            }
        }

        &.without-padding {
            .pdf__content {
                padding: 0;
            }
        }
    }

    &__content {
        height: 81rem;
        padding: $medium-spacing 4rem;
        position: relative;
        width: 100%;

        h2 {
            font-size: 2.6rem;
            font-weight: $extraBold;
            line-height: 3.4rem;
        }

        h4 {
            font-size: 1.4rem;
            font-weight: $bold;
            line-height: 2.2rem;
            margin: $small-spacing 0;
        }

        p {
            font-size: 1.2rem;
            font-weight: $light;
            line-height: 2rem;
            margin-top: $small-spacing;

            strong {
                font-weight: $bold;
            }
        }
    }

    &__text--justify {
        text-align: justify;
    }

    &__text--strong {
        font-weight: 900;
    }

    &__title {
        h2 {
            margin-bottom: $medium-spacing;
        }

        h3 {
            font-size: 1.6rem;
            line-height: 3.7rem;
            margin: $base-spacing 0 1.8rem;
        }
    }
}

.color--pink {
    color: $pinkRed;
}
