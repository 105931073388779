@import 'styles/_variables';

.situation-card-checkbox {
    align-items: flex-start;
    background-color: $white;
    border: 0.1rem solid $greyCloudy;
    border-radius: $borderRadius;
    color: $black;
    display: flex;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: $small-spacing;
    min-height: 5.6rem;
    padding: $small-spacing;
    text-align: left;
    width: 100%;

    &:hover {
        box-shadow: 0 0.8rem $small-spacing $grey-box-shadow;
    }

    img {
        margin-right: 0.8rem;
    }

    &.situation-card-checkbox--primary {
        border: 0.2rem solid $pinkRed;

        img {
            visibility: visible;
        }
    }

    span {
        font-size: 1.8rem;
        font-weight: $bold;
        line-height: 2.6rem;
    }
}
