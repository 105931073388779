@import 'styles/_variables';

.not-found-button {
  background-color: $black;
  border: 0;
  border-radius: $borderRadius;
  color: $white;
  font-size: 1.6rem;
  font-weight: $light;
  height: 5rem;
  padding: 1.2rem $base-spacing;
  text-decoration: none;

  @media screen and (max-width: $breakpoint-mobile) {
    text-align: center;
  }

  &:hover {
    background-color: lighten($black, 20%);
  }
}
