@import 'styles/_variables';

.select-field {
    margin-bottom: $small-spacing;

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

.select {
    &__control {
        box-shadow: none !important;

        &--is-focused {
            border-color: $black !important;
            border-width: 0.2rem !important;
        }
    }

    &__indicator {
        color: $black !important;
    }

    &__indicator-separator {
        display: none !important;
    }
}
