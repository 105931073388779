@import '../Button/style.scss';

.button-icon {
    background: none;
    height: 4rem;
    width: 4rem;

    img {
        margin: 0;
    }

    &.share-results-modal__close {
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
    }
}
