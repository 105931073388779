@import 'styles/_variables';
@import 'react-confirm-alert/src/react-confirm-alert.css';

.react-confirm-alert-overlay {
    background-color: inherit;
    overflow: auto;

    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
        justify-content: inherit;
        width: 100%;
    }
}

.react-confirm-alert {
    @media screen and (max-width: $breakpoint-mobile) {
        height: 100%;
    }
}

.modal {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    position: fixed;
    z-index: 900;

    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        height: auto;
        left: 50%;
        max-width: 56.4rem;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    &__inner {
        background-color: $white;
        padding: 2rem;

        @media screen and (min-width: $breakpoint-mobile) {
            border-radius: $borderRadius;
            padding: $medium-spacing;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        right: 2rem;
        top: $base-spacing;

        @media screen and (min-width: $breakpoint-mobile) {
            height: 1.6rem;
            top: $medium-spacing;
            width: 1.6rem;
        }
    }

    &__title {
        font-size: 2.6rem;
        font-weight: bold;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;

        & > * {
            width: 100%;
        }

        & > :nth-child(2) {
            margin-left: $base-spacing;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;

            & > :nth-child(2) {
                margin-left: 0;
                margin-top: $base-spacing;
            }
        }
    }

    &--fullscreen {
        .modal__inner {
            @media screen and (max-width: $breakpoint-mobile) {
                min-height: 100%;
                padding: $base-spacing 2rem;
                position: relative;
            }
        }
    }
}
