@import "styles/_variables.scss";

.account__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: $breakpoint-mobile) {
        align-items: flex-start;
        height: auto;
        justify-content: inherit;
        max-width: inherit;
        padding: 2rem;
        text-align: left;
    }
}

.account__instructions {
    font-weight: $semiBold;
    line-height: 2.4rem;
    margin-top: $small-spacing;

    @media screen and (min-width: $breakpoint-mobile) {
        margin-top: $base-spacing;
    }
}

.account__buttons {
    @media screen and (min-width: $breakpoint-mobile) {
        & > :nth-child(2) {
            margin-left: $small-spacing;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
