@import 'styles/_variables';
@import 'styles/_fonts';
@import 'styles/_form';
@import 'styles/_reset';
@import 'styles/_static';
@import 'styles/_texts';
@import 'styles/_utils';

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: -webkit-fill-available;
    font-size: calc(1em * 0.625);
}

body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-stretch: normal;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.4;
    color: $black;

    @media screen and (max-width: $breakpoint-mobile) {
        position: relative;
        overflow: visible;
    }
}

img {
    max-width: 100%;
}

hr {
    border: none;
    border-top: 0.1rem solid $borderColor;
    margin: 1.6rem 0;
}

#app {
    height:100vh;
    display:flex;
    flex-direction:column;

    @media screen and (max-width: $breakpoint-mobile) {
        height: unset;
        min-height: 100%;
    }
}

@media print {
    html {
        position: relative !important;
        height: auto;
    }

    body {
        overflow-y: auto !important;
        position: relative !important;
    }

    #app {
        display: block !important;
    }
}
