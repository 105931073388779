@import "styles/_variables";

.home-content {
    padding: 9.6rem 5.3rem 0;

    @media screen and (min-width: $breakpoint-desktop) {
        padding: 0;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        padding: $medium-spacing 2rem 0;
    }

    &__container {
        margin: auto;
        max-width: $max-width-home;
        position: relative;
        width: 100%;
    }

    &__logo {
        display: block;
        margin-right: 4rem;

        img {
            max-height: 7rem;
        }
    }

    &__title {
        font-size: 3.2rem;
        font-weight: $extraBold;
        line-height: 4.2rem;
        margin-bottom: 0.8rem;

        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 3rem;
        }
    }

    &__text {
        line-height: $base-spacing;
        margin-bottom: $medium-spacing;
        @media screen and (max-width: $breakpoint-mobile) {
            margin-bottom: $base-spacing;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            &--top {
                margin-left: 3rem;
                max-width: 46rem;
                z-index: 11;
            }
        }
    }

    &__top {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            text-align: left;
        }

        .home-block-cards {
            margin-top: 4.8rem;
            position: relative;
            width: 100%;

            > :first-child {
                margin-bottom: $base-spacing;
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                padding: 0;

                > :first-child {
                    margin-bottom: $base-spacing;
                }

                @media screen and (min-width: $breakpoint-mobile) {
                    flex-direction: row;
                    padding: 0 1.2rem;

                    > :first-child {
                        margin-bottom: 0;
                    }
                }
            }

            @media screen and (min-width: $breakpoint-desktop) {
                display: none;
            }
        }
    }

    &__end {
        align-items: center;
        background-color: $pinkRed;
        display: flex;
        flex-direction: column;
        margin-top: -1rem;
        padding: 6rem 8.5%;
        position: relative;
        z-index: 1;

        @media screen and (min-width: $breakpoint-mobile) {
            padding-bottom: $big-spacing;
            padding-top: $big-spacing;
        }

        &-title {
            font-size: 4.2rem;
            font-weight: $extraBold;
            line-height: 1.1;
            margin: auto auto $medium-spacing;
            max-width: 80rem;
            text-align: center;
        }
    }

    &__bottom {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-bottom: -0.1rem;
        position: relative;

        @media screen and (min-width: $breakpoint-desktop) {
            flex-direction: row;
            margin-top: 9.6rem;
        }

        &-text {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            max-width: none;
            text-align: center;

            @media screen and (min-width: $breakpoint-desktop) {
                align-items: flex-start;
                margin-left: 8.5%;
                text-align: left;
            }
        }

        &-image {
            max-width: 100%;
        }

        &-logos {
            display: flex;
            margin-top: $base-spacing;
        }
    }
}

.home-cards {
    display: none;

    @media screen and (min-width: $breakpoint-desktop) {
        display: flex;
        flex-direction: column;
        margin-top: -$base-spacing;
        position: relative;
        z-index: 100;

        .home-card {
            margin: 0 0 3.2rem;
        }
    }
}

.home-expectation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: $big-spacing auto 0;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
        align-items: center;
        flex-direction: row;
        margin: 9.6rem auto 0;
        text-align: left;
    }

    &__text {
        margin-bottom: $medium-spacing;

        @media screen and (min-width: $breakpoint-desktop) {
            margin: 0;
            max-width: 50rem;
        }
    }
}

.home-your-way {
    align-items: center;
    border: 0.1rem solid $grey;
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    margin: 9.6rem auto;
    padding: $base-spacing;
    text-align: center;
    width: 100%;

    @media screen and (min-width: $breakpoint-desktop) {
        max-width: 76rem;
        text-align: left;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        margin: $medium-spacing auto;
    }

    &__text {
        margin-top: 3rem;

        @media screen and (min-width: $breakpoint-mobile) {
            margin-left: $base-spacing;
            margin-top: 0;
        }

        @media screen and (min-width: $breakpoint-desktop) {
            max-width: 50rem;
        }

        p {
            margin-top: 0;
        }
    }

    &__title {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }

    &__other-products {
        list-style: unset;
        margin-left: $base-spacing;

        a {
            color: black;
        }
    }

    .button {
        font-size: 1.4rem;
        font-weight: $light;
        height: unset;
        line-height: 2.2rem;
        margin-top: $small-spacing;
        min-width: 10.5rem;
        padding: 0.7rem $small-spacing;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }
    }
}
