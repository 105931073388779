@import 'styles/_variables';

.home-card {
    align-items: center;
    background-color: $greyLighter;
    border-radius: $borderRadius;
    display: flex;
    height: 12.2rem;
    padding: 2.9rem $medium-spacing;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: $base-spacing $medium-spacing;
        text-align: left;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        max-width: 56.3rem;
    }
}

.home-card__img {
    height: 6.4rem;
    width: 6.4rem;

    @media screen and (max-width: $breakpoint-mobile) {
        align-self: flex-start;
        height: 4.8rem;
        width: 4.8rem;
    }
}

.home-card__content {
    margin-left: $small-spacing;

    .home-card__title {
        font-size: 1.8rem;
        font-weight: $extraBold;
        line-height: 2.6rem;

        @media screen and (max-width: $breakpoint-small-mobile) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    p {
        margin: 0;
        @media screen and (max-width: $breakpoint-small-mobile) {
            font-size: 1.4rem;
        }
    }
}
