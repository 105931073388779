/* COLORS */
$black: #000;
$blue: #00cddd;
$blueDark: desaturate($blue, 30%);
$darkBackground: rgba(255, 255, 255, 0.1);
$emerald: #00a34d;
$green: #4dc942;
$grey: #bdbdc0;
$greyCloudy: #e3e3e7;
$greyGraphite: #767678;
$greyGraphiteLight: #717173;
$greyLighter: #f5f5f5;
$greyLighter2: #f6f6f9;
$greyLighter3: #C4C4C4;
$pink: #ff8dff;
$pinkRed: #ff005f;
$lightRed: #fdf4f7;
$white: #fff;

/* THEME */
$backgroundBlackWithCard: linear-gradient(180deg, $black 50%, $white 50%);
$backgroundColor: $greyLighter;
$borderColor: $grey;
$borderRadius--large: 2.4rem;
$borderRadius--medium: 1.2rem;
$borderRadius--small: 0.8rem;
$borderRadius: 0.4rem;
$primary: $pinkRed;

/* BREAKPOINTS */
$breakpoint-desktop: 1200px;
$breakpoint-mobile: 970px;
$breakpoint-small-mobile: 350px;
$breakpoint-tablet: 1024px;

/* FONT WEIGHTS */
$extraLight: 300;
$light: 400;
$bold: 700;
$extraBold: 800;
$semiBold: 600;

/* OTHER */
$box-shadow: rgba(0, 0, 0, 0.2);
$grey-box-shadow: rgba(118, 118, 120, 0.2);
$header-height: 7.8rem;
$panel-width: 30.4rem;

$max-width-home: 116rem;
$max-width-main-large: 86.4rem;
$max-width-main: 66.4rem;

$base-spacing: 2.4rem;
$big-spacing: 6.4rem;
$medium-spacing: 3.6rem;
$small-spacing: 1.6rem;
$x-small-spacing: 0.4rem;

/* stylelint-disable */
:export {
    black: $black;
    bordercolor: $borderColor;
}

/* stylelint-enable */
